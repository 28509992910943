import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/CategoryPageLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Audios App`}</h1>
    <p>{`Learn how to upload and associate audio files to be used in teams and skills.`}</p>
    <h2>{`What is it for`}</h2>
    <p>{`Audios App is an application of the CoreMedia Contact Center and Analytics Studio that allows you to centrally manage your audio files. Use audio files in the following situations:`}</p>
    <ul>
      <li parentName="ul">{`Beyond business hours`}</li>
      <li parentName="ul">{`Intro audios`}</li>
      <li parentName="ul">{`Music on Hold`}</li>
      <li parentName="ul">{`Operator identification`}</li>
      <li parentName="ul">{`Voicemail detection.`}</li>
    </ul>
    <p>{`Note that Audios App doesn't allow you to manage audio files inside your website.`}</p>
    <h2>{`What can I do with it`}</h2>
    <p>{`Audios App has the following functionality:`}</p>
    <ul>
      <li parentName="ul">{`Upload new audio files`}</li>
      <li parentName="ul">{`Associate audios with different languages`}</li>
      <li parentName="ul">{`Edit audio file details`}</li>
      <li parentName="ul">{`Associate audio files with team skills`}</li>
      <li parentName="ul">{`Activate and inactivate audios.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      